define("discourse/plugins/discourse-encrypt/lib/utils", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.filterObjectKeys = filterObjectKeys;
  _exports.getCaseInsensitiveObj = getCaseInsensitiveObj;
  /**
   * Wraps an object in a proxy which makes lookups case insensitive.
   *
   * @param {Object} object
   * @return {Proxy}
   */
  function getCaseInsensitiveObj(object) {
    object = object || {};
    return new Proxy(object, {
      get(obj, key) {
        if (obj[key]) {
          return obj[key];
        }
        key = key.toLowerCase();
        key = Object.keys(obj).find(k => key === k.toLowerCase());
        return obj[key];
      }
    });
  }

  /**
   * Creates a new object containing a subset of the boject keys.
   *
   * @param {Object} obj
   * @param {Array<String>} keys
   *
   * @return {Object}
   */
  function filterObjectKeys(obj, keys) {
    const newObj = {};
    keys.forEach(key => {
      if (key in obj) {
        newObj[key] = obj[key];
      }
    });
    return newObj;
  }
});