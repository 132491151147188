define("discourse/plugins/discourse-encrypt/discourse/widgets/encrypted-post-timer-counter", ["@ember/runloop", "discourse-common/lib/icon-library", "discourse/widgets/widget", "I18n", "virtual-dom"], function (_runloop, _iconLibrary, _widget, _I18n, _virtualDom) {
  "use strict";

  (0, _widget.createWidget)("encrypted-post-timer-counter", {
    tagName: "div.encrypted-post-timer-counter",
    init(attrs) {
      if (attrs.post.delete_at) {
        (0, _runloop.later)(() => {
          this.scheduleRerender();
        }, 60000);
      }
    },
    formattedClock(attrs) {
      const miliseconds = Math.max(moment(attrs.post.delete_at) - moment().utc(), 60000);
      return moment.duration(miliseconds).humanize();
    },
    html(attrs) {
      if (attrs.post.delete_at) {
        return (0, _virtualDom.h)("div", {
          attributes: {
            title: _I18n.default.t("encrypt.time_bomb.title", {
              after: this.formattedClock(attrs)
            })
          }
        }, [(0, _iconLibrary.iconNode)("discourse-trash-clock"), this.formattedClock(attrs)]);
      }
    }
  });
});