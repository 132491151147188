define("discourse/plugins/discourse-encrypt/lib/discourse", ["exports", "discourse-common/config/environment", "discourse/lib/ajax", "discourse/plugins/discourse-encrypt/lib/database", "discourse/plugins/discourse-encrypt/lib/pack", "discourse/plugins/discourse-encrypt/lib/paper-key", "discourse/plugins/discourse-encrypt/lib/protocol", "discourse/plugins/discourse-encrypt/lib/utils", "rsvp"], function (_exports, _environment, _ajax, _database, _pack, _paperKey, _protocol, _utils, _rsvp) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.ENCRYPT_ENABLED = _exports.ENCRYPT_DISABLED = _exports.ENCRYPT_ACTIVE = void 0;
  _exports.activateEncrypt = activateEncrypt;
  _exports.enableEncrypt = enableEncrypt;
  _exports.getEncryptionStatus = getEncryptionStatus;
  _exports.getIdentity = getIdentity;
  _exports.getTopicKey = getTopicKey;
  _exports.getTopicTitle = getTopicTitle;
  _exports.getUserIdentities = getUserIdentities;
  _exports.hasTopicKey = hasTopicKey;
  _exports.hasTopicTitle = hasTopicTitle;
  _exports.putTopicKey = putTopicKey;
  _exports.putTopicTitle = putTopicTitle;
  _exports.resetEncrypt = resetEncrypt;
  _exports.syncGetTopicTitle = syncGetTopicTitle;
  _exports.waitForPendingTitles = waitForPendingTitles;
  /*
   * Possible states of the encryption system.
   */

  /**
   * @var {Number} ENCRYPT_DISABLED User does not have any generated keys
   */
  const ENCRYPT_DISABLED = _exports.ENCRYPT_DISABLED = 0;

  /**
   * @var {Number} ENCRYPT_ENABLED User has keys, but only on server
   */
  const ENCRYPT_ENABLED = _exports.ENCRYPT_ENABLED = 1;

  /**
   * @var {Number} ENCRYPT_ACTIVE User has imported server keys into browser
   */
  const ENCRYPT_ACTIVE = _exports.ENCRYPT_ACTIVE = 2;

  /**
   * @var {Promise<Object>} userIdentity Current user's identity.
   */
  let userIdentity;

  /**
   * @var {Object} userIdentities Cached user identities.
   */
  const userIdentities = (0, _utils.getCaseInsensitiveObj)();

  /**
   * @var {Object} topicKeys Dictionary of all topic keys (topic_id => key).
   */
  const topicKeys = {};

  /**
   * @var {Object} topicTitles Dictionary of all topic title objects (topic_id => TopicTitle).
   */
  const topicTitles = {};
  class TopicTitle {
    constructor(topicId, encrypted) {
      this.topicId = topicId;
      this.encrypted = encrypted;
    }
    get promise() {
      if (!this._promise) {
        this._promise = getTopicKey(this.topicId).then(key => (0, _protocol.decrypt)(key, this.encrypted)).then(decrypted => decrypted.raw).then(result => this.result = result);
      }
      return this._promise;
    }
  }

  /**
   * Resets loaded keys
   */
  function resetEncrypt() {
    if (!(0, _environment.isTesting)()) {
      throw new Error("`resetEncrypt` can be called from tests only");
    }
    userIdentity = null;
    for (const key in topicKeys) {
      if (topicKeys.hasOwnProperty(key)) {
        delete topicKeys[key];
      }
    }
    for (const key in topicTitles) {
      if (topicTitles.hasOwnProperty(key)) {
        delete topicTitles[key];
      }
    }
  }

  /**
   * Gets current user's identity from the database and caches it for future
   * usage.
   *
   * @return {Promise}
   */
  function getIdentity() {
    if (!userIdentity) {
      userIdentity = (0, _database.loadDbIdentity)();
    }
    return userIdentity;
  }

  /**
   * Gets users' identities from the server and caches them for future usage
   *
   * @return {Promise}
   */
  function getUserIdentities(usernames) {
    // If some of the user identities are missing, then try to refresh all of
    // the newly requested ones.
    if (usernames.some(username => !userIdentities[username])) {
      const promise = (0, _ajax.ajax)("/encrypt/user", {
        type: "GET",
        data: {
          usernames
        }
      }).then(identities => (0, _utils.getCaseInsensitiveObj)(identities));
      usernames.forEach(username => {
        userIdentities[username] = promise.then(identities => identities[username] ? (0, _protocol.importIdentity)(identities[username]) : _rsvp.Promise.reject(username));
      });
    }
    return _rsvp.Promise.all(usernames.map(username => userIdentities[username])).then(identities => {
      const imported = {};
      for (let i = 0; i < usernames.length; ++i) {
        imported[usernames[i]] = identities[i];
      }
      return (0, _utils.getCaseInsensitiveObj)(imported);
    });
  }

  /**
   * Puts a topic key into storage.
   *
   * If there is a key in the store already, it will not be overwritten.
   *
   * @param {Number|String} topicId
   * @param {String} key
   */
  function putTopicKey(topicId, key) {
    if (topicId && key) {
      topicKeys[topicId] = key;
    }
  }

  /**
   * Gets a topic key from storage.
   *
   * @param {Number|String} topicId
   *
   * @return {Promise<CryptoKey>}
   */
  function getTopicKey(topicId) {
    let key = topicKeys[topicId];
    if (!key) {
      return _rsvp.Promise.reject();
    } else if (key instanceof CryptoKey) {
      return _rsvp.Promise.resolve(key);
    } else if (!(key instanceof _rsvp.Promise || key instanceof window.Promise)) {
      topicKeys[topicId] = getIdentity().then(identity => (0, _protocol.importKey)(key, identity.encryptPrivate));
    }
    return topicKeys[topicId];
  }

  /**
   * Checks if there is a topic key for a topic.
   *
   * @param {Number|String} topicId
   *
   * @return {Boolean}
   */
  function hasTopicKey(topicId) {
    return !!topicKeys[topicId];
  }

  /**
   * Puts a topic title into storage.
   *
   * @param {Number|String} topicId
   * @param {String} title
   */
  function putTopicTitle(topicId, title) {
    if (!(topicId && title)) {
      return;
    }
    if (topicTitles[topicId] && topicTitles[topicId].encrypted === title) {
      return;
    }
    topicTitles[topicId] = new TopicTitle(topicId, title);
  }

  /**
   * Gets a topic title from storage.
   *
   * @param {Number|String} topicId
   *
   * @return {Promise<String>}
   */
  function getTopicTitle(topicId) {
    const title = topicTitles[topicId];
    if (!title) {
      return _rsvp.Promise.reject();
    }
    return title.promise;
  }

  /**
   * Gets a topic title from storage synchronously, returning null if missing or unresolved
   *
   * @param {Number|String} topicId
   *
   * @return {String|null}
   */
  function syncGetTopicTitle(topicId) {
    const title = topicTitles[topicId];
    if (!title) {
      return null;
    }
    return title.result;
  }

  /**
   * Checks if there is an encrypted topic title for a topic.
   *
   * @param {Number|String} topicId
   *
   * @return {Boolean}
   */
  function hasTopicTitle(topicId) {
    return !!topicTitles[topicId];
  }

  /**
   * Returns a promise which resolves when all stored  titles are decrypted
   *
   * @return {Promise}
   */
  function waitForPendingTitles() {
    return _rsvp.Promise.all(Object.values(topicTitles).filter(t => !t.result).map(t => t.promise));
  }

  /*
   * Plugin management
   */

  /**
   * Gets current encryption status.
   *
   * @param {User} user
   *
   * @return {Number} See `ENCRYPT_DISABLED`, `ENCRYPT_ENABLED` and
   *                  `ENCRYPT_ACTIVE`.
   */
  function getEncryptionStatus(user) {
    if (!user || !user.can_encrypt || !user.encrypt_public) {
      return ENCRYPT_DISABLED;
    }
    if (!window.localStorage.getItem(_database.DB_NAME) || !window.localStorage.getItem(_database.DB_VERSION)) {
      return ENCRYPT_ENABLED;
    }
    return ENCRYPT_ACTIVE;
  }
  function enableEncrypt(model, exportedIdentity) {
    const identityPromise = exportedIdentity ? (0, _protocol.importIdentity)((0, _pack.unpackIdentity)(exportedIdentity)) : (0, _protocol.generateIdentity)();
    const saveIdentityPromise = identityPromise.then(identity => (0, _protocol.exportIdentity)(identity)).then(exported => {
      model.set("encrypt_public", exported.public);
      return (0, _ajax.ajax)("/encrypt/keys", {
        type: "PUT",
        data: {
          public: exported.public
        }
      });
    });
    const saveDbIdentityPromise = identityPromise.then(identity => (0, _database.saveDbIdentity)(identity));
    return _rsvp.Promise.all([saveIdentityPromise, saveDbIdentityPromise]);
  }

  /**
   * Attempts at activating encryption on current device.
   *
   * @param {User} currentUser
   * @param {String} passphrase
   *
   * @return {Promise}
   */
  function activateEncrypt(currentUser, passphrase) {
    const privateKeys = JSON.parse(currentUser.encrypt_private);
    let promise = _rsvp.Promise.reject();

    // User may have no private keys if they did not generate any private keys.
    if (!privateKeys) {
      return promise;
    }

    // Importing from a paper key.
    const spacePos = passphrase.indexOf(" ");
    if (spacePos !== -1) {
      const label = "paper_" + passphrase.substr(0, spacePos).toLowerCase();
      if (privateKeys[label]) {
        promise = promise.catch(() => (0, _protocol.importIdentity)(privateKeys[label], (0, _paperKey.fixPaperKey)(passphrase)));
      }
    }

    // Importing from a device key.
    if (privateKeys["device"]) {
      promise = promise.catch(() => (0, _protocol.importIdentity)(privateKeys["device"], (0, _paperKey.fixPaperKey)(passphrase)));
    }

    // Importing from a passphrase key.
    if (privateKeys["passphrase"]) {
      promise = promise.catch(() => (0, _protocol.importIdentity)(privateKeys["passphrase"], passphrase));
    }
    return promise.then(identity => (0, _database.saveDbIdentity)(identity));
  }
});