define("discourse/plugins/discourse-encrypt/discourse/initializers/invite-to-encrypted-topic", ["exports", "discourse/lib/ajax", "discourse/lib/plugin-api", "discourse/plugins/discourse-encrypt/lib/discourse", "discourse/plugins/discourse-encrypt/lib/protocol", "I18n", "rsvp"], function (_exports, _ajax, _pluginApi, _discourse, _protocol, _I18n, _rsvp) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    name: "invite-to-encrypted-topic",
    initialize(container) {
      const currentUser = container.lookup("service:current-user");
      if ((0, _discourse.getEncryptionStatus)(currentUser) !== _discourse.ENCRYPT_ACTIVE) {
        return;
      }
      (0, _pluginApi.withPluginApi)("0.11.3", api => {
        api.modifyClass("model:topic", {
          pluginId: "invite-to-encrypted-topic",
          createInvite(user, group_ids, custom_message) {
            // TODO: https://github.com/emberjs/ember.js/issues/15291
            let {
              _super
            } = this;
            if (!(0, _discourse.hasTopicKey)(this.id)) {
              return _super.call(this, ...arguments);
            }
            return _rsvp.Promise.all([(0, _discourse.getTopicKey)(this.id), (0, _discourse.getUserIdentities)([user])]).then(_ref => {
              let [key, identities] = _ref;
              return (0, _protocol.exportKey)(key, identities[user].encryptPublic);
            }).then(key => {
              (0, _ajax.ajax)(`/t/${this.id}/invite`, {
                type: "POST",
                data: {
                  user,
                  key,
                  group_ids,
                  custom_message
                }
              });
            }).catch(username => {
              const dialog = container.lookup("service:dialog");
              dialog.alert(_I18n.default.t("encrypt.composer.user_has_no_key", {
                username
              }));
              return _rsvp.Promise.reject(username);
            });
          }
        });
      });
    }
  };
});