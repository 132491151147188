define("discourse/plugins/discourse-encrypt/discourse/initializers/encrypt-drafts", ["exports", "discourse/lib/plugin-api", "discourse/lib/text", "discourse/lib/utilities", "discourse/plugins/discourse-encrypt/lib/discourse", "discourse/plugins/discourse-encrypt/lib/protocol", "discourse/plugins/discourse-encrypt/lib/utils", "I18n", "rsvp"], function (_exports, _pluginApi, _text, _utilities, _discourse, _protocol, _utils, _I18n, _rsvp) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const ALLOWED_DRAFT_FIELDS = ["action", "archetypeId", "categoryId", "composerTime", "noBump", "postId", "reply",
  // will be encrypted
  "tags", "title",
  // will be encrypted
  "recipients", "whisper"];
  let globalContainer;
  var _default = _exports.default = {
    name: "encrypt-drafts",
    initialize(container) {
      const currentUser = container.lookup("service:current-user");
      if ((0, _discourse.getEncryptionStatus)(currentUser) !== _discourse.ENCRYPT_ACTIVE) {
        return;
      }

      // In testing environment, the initializer will be called on every `visit`
      // call. As a result, `Draft` class will be patched multiple times. The
      // following lines ensure that the patch is applied only once (the first
      // time, when there is no old "container"). However, the reference to
      // `container` must be updated every time the initializer is called
      // because it is used inside the patched method.
      let initializedBefore = !!globalContainer;
      globalContainer = container;
      if (initializedBefore) {
        return;
      }
      (0, _pluginApi.withPluginApi)("0.11.3", api => {
        api.modifyClassStatic("model:draft", {
          pluginId: "encrypt-drafts",
          save(draftKey, sequence, data, clientId) {
            // TODO: https://github.com/emberjs/ember.js/issues/15291
            let {
              _super
            } = this;
            if (!globalContainer || globalContainer.isDestroyed || globalContainer.isDestroying) {
              // Since at this point we cannot be sure if it is an encrypted
              // topic or not, the draft is simply discarded.
              return _rsvp.Promise.reject();
            }
            const controller = globalContainer.lookup("controller:composer");
            let encrypted = !!controller.get("model.isEncrypted");
            if (draftKey.indexOf("topic_") === 0) {
              const topicId = draftKey.substr("topic_".length);
              encrypted = !!(0, _discourse.hasTopicKey)(topicId);
            }
            if (encrypted) {
              data = (0, _utils.filterObjectKeys)(data, ALLOWED_DRAFT_FIELDS);
              if (!data.title && !data.reply) {
                return _super.call(this, ...arguments);
              }
              const topicKey = (0, _protocol.generateKey)();
              const encKey = _rsvp.Promise.all([topicKey, (0, _discourse.getIdentity)()]).then(_ref => {
                let [key, identity] = _ref;
                return (0, _protocol.exportKey)(key, identity.encryptPublic);
              });
              const encTitle = data.title ? topicKey.then(key => (0, _protocol.encrypt)(key, data.title)) : "";
              const encReply = data.reply ? topicKey.then(key => (0, _protocol.encrypt)(key, {
                raw: data.reply
              }, {
                includeUploads: true
              })) : "";
              return _rsvp.Promise.all([encTitle, encReply, encKey]).then(_ref2 => {
                let [title, reply, key] = _ref2;
                data.title = title;
                data.reply = key + "\n" + reply;
                data.encrypted = true;
                return _super.call(this, draftKey, sequence, data, clientId);
              });
            }
            return _super.call(this, ...arguments);
          }
        });
        api.modifyClass("model:user-drafts-stream", {
          pluginId: "encrypt-drafts",
          findItems(site) {
            return this._super(site).then(() => {
              this.content.forEach(draft => {
                if (draft.data.encrypted) {
                  draft.setProperties({
                    title: (0, _text.emojiUnescape)((0, _utilities.escapeExpression)(":lock: " + _I18n.default.t("encrypt.encrypted_title"))),
                    excerpt: _I18n.default.t("encrypt.encrypted_post")
                  });
                }
              });
            });
          }
        });
      });
    }
  };
});