define("discourse/plugins/discourse-encrypt/discourse/initializers/encrypt-composer", ["exports", "discourse-common/utils/decorators", "discourse/lib/plugin-api", "discourse/models/composer", "discourse/plugins/discourse-encrypt/lib/discourse", "discourse/plugins/discourse-encrypt/lib/protocol", "I18n", "rsvp"], function (_exports, _decorators, _pluginApi, _composer, _discourse, _protocol, _I18n, _rsvp) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  var _default = _exports.default = {
    name: "encrypt-composer",
    initialize(container) {
      const currentUser = container.lookup("service:current-user");
      if ((0, _discourse.getEncryptionStatus)(currentUser) !== _discourse.ENCRYPT_ACTIVE) {
        return;
      }

      // Register custom fields to be saved for new post.
      _composer.default.serializeOnCreate("is_encrypted", "isEncrypted");
      _composer.default.serializeOnCreate("delete_after_minutes", "deleteAfterMinutes");
      (0, _pluginApi.withPluginApi)("0.11.3", api => {
        var _dec, _dec2, _dec3, _dec4, _dec5, _obj;
        // Check recipients and show encryption status in composer.
        api.modifyClass("model:composer", (_dec = (0, _decorators.on)("init"), _dec2 = (0, _decorators.observes)("creatingPrivateMessage", "topic"), _dec3 = (0, _decorators.observes)("targetRecipients"), _dec4 = (0, _decorators.default)("topic.encrypted_title", "topic.id"), _dec5 = (0, _decorators.default)("encryptErrorMissingKey", "encryptErrorUser", "encryptErrorGroup"), (_obj = {
          pluginId: "encrypt-composer",
          updateEncryptProperties() {
            let isEncrypted = this.isEncrypted;
            if (this.topic && this.topic.encrypted_title && (0, _discourse.hasTopicKey)(this.topic.id)) {
              // Force encryption for existing encrypted topics.
              isEncrypted = true;
            } else if (this.isNew && this.creatingPrivateMessage) {
              // `isEncryptedChanged` is set true only when the value of
              // `isEncrypted` is changed. This is needed because during save
              // (serialization), this method is called and `isEncrypted` is
              // reset.
              if (!this.isEncryptedChanged) {
                isEncrypted = currentUser.encrypt_pms_default;
              }
            }
            this.setProperties({
              /** @var Whether the current message is going to be encrypted. */
              isEncrypted,
              /** @var Whether current error is shown or not. In most cases, it
               *       is equal to `isEncrypted` except when `isEncrypted` is
               *       forcibly set to false (i.e. when an error occurs).
               */
              showEncryptError: isEncrypted
            });
          },
          checkEncryptRecipients() {
            if (!this.targetRecipients || this.targetRecipients.length === 0) {
              this.setProperties({
                isEncrypted: currentUser.encrypt_pms_default,
                isEncryptedChanged: true,
                showEncryptError: true,
                encryptErrorUser: false,
                encryptErrorGroup: false
              });
              return;
            }
            const recipients = this.targetRecipients.split(",");
            recipients.push(this.user.username);
            const allGroupNames = new Set(this.site.groups.map(g => g.name.toLowerCase()));
            const groups = recipients.filter(r => allGroupNames.has(r.toLowerCase()));
            if (groups.length > 0) {
              this.setProperties({
                isEncrypted: false,
                isEncryptedChanged: true,
                showEncryptError: this.showEncryptError || this.isEncrypted,
                encryptErrorGroup: true
              });
            } else {
              this.setProperties({
                encryptErrorGroup: false
              });
            }
            const usernames = recipients.filter(r => !allGroupNames.has(r.toLowerCase()));
            (0, _discourse.getUserIdentities)(usernames).then(() => {
              this.setProperties({
                encryptErrorUser: false
              });
            }).catch(username => {
              this.setProperties({
                isEncrypted: false,
                isEncryptedChanged: true,
                showEncryptError: this.showEncryptError || this.isEncrypted,
                encryptErrorUser: username
              });
            });
          },
          encryptErrorMissingKey(encryptedTitle, topicId) {
            return encryptedTitle && !(0, _discourse.hasTopicKey)(topicId);
          },
          encryptError(missingKey, username, group) {
            if (missingKey) {
              return _I18n.default.t("encrypt.composer.no_topic_key");
            } else if (username) {
              return _I18n.default.t("encrypt.composer.user_has_no_key", {
                username
              });
            } else if (group) {
              return _I18n.default.t("encrypt.composer.group_not_allowed");
            }
          },
          beforeSave() {
            if (!this.showEncryptError || !this.encryptError) {
              return _rsvp.Promise.resolve();
            }
            const dialog = container.lookup("service:dialog");
            return new _rsvp.Promise((resolve, reject) => {
              dialog.yesNoConfirm({
                message: _I18n.default.t("encrypt.composer.confirm.message", {
                  error: this.encryptError
                }),
                didConfirm: () => resolve(),
                didCancel: () => reject()
              });
            });
          }
        }, (_applyDecoratedDescriptor(_obj, "updateEncryptProperties", [_dec, _dec2], Object.getOwnPropertyDescriptor(_obj, "updateEncryptProperties"), _obj), _applyDecoratedDescriptor(_obj, "checkEncryptRecipients", [_dec3], Object.getOwnPropertyDescriptor(_obj, "checkEncryptRecipients"), _obj), _applyDecoratedDescriptor(_obj, "encryptErrorMissingKey", [_dec4], Object.getOwnPropertyDescriptor(_obj, "encryptErrorMissingKey"), _obj), _applyDecoratedDescriptor(_obj, "encryptError", [_dec5], Object.getOwnPropertyDescriptor(_obj, "encryptError"), _obj)), _obj)));
      });

      // Decode composer on reply reload. This usually occurs when a post is
      // edited or a draft is loaded.
      const appEvents = container.lookup("service:app-events");
      appEvents.on("composer:reply-reloaded", this, this.composerReplyReloaded);
    },
    composerReplyReloaded(model) {
      if (!model.privateMessage) {
        return;
      }
      let decTitle, decReply;
      if (model.action === "edit" && model.originalText) {
        const topicId = model.get("topic.id");
        if (!(0, _discourse.hasTopicKey)(topicId)) {
          return;
        }
        decTitle = (0, _discourse.getTopicTitle)(topicId);
        decReply = (0, _discourse.getTopicKey)(topicId).then(key => (0, _protocol.decrypt)(key, model.reply)).then(decrypted => decrypted.raw);
      } else {
        const pos = model.reply ? model.reply.indexOf("\n") : -1;
        if (pos === -1) {
          return;
        }
        const topicKey = model.reply.substr(0, pos).trim();
        const reply = model.reply.substr(pos + 1).trim();
        const decKey = (0, _discourse.getIdentity)().then(identity => (0, _protocol.importKey)(topicKey, identity.encryptPrivate));
        if (model.title) {
          decTitle = decKey.then(key => (0, _protocol.decrypt)(key, model.title));
        }
        if (reply) {
          decReply = decKey.then(key => (0, _protocol.decrypt)(key, reply)).then(decrypted => decrypted.raw);
        }
      }
      if (decTitle) {
        decTitle.then(title => model.setProperties({
          title,
          isEncrypted: true,
          isEncryptedChanged: true
        }));
      }
      if (decReply) {
        decReply.then(reply => model.setProperties({
          reply,
          isEncrypted: true,
          isEncryptedChanged: true
        }));
      }
    }
  };
});